<template>
  <div class="home_wrap">
    <div class="top_banner">
      <el-carousel :interval="5000"
                   arrow="always">
        <el-carousel-item>
          <img src="../assets/home2/banner1.png"
               alt="">
        </el-carousel-item>
        <el-carousel-item>
          <img src="../assets/home2/banner2.png"
               alt="">
        </el-carousel-item>
        <el-carousel-item>
          <img src="../assets/home2/banner3.png"
               alt="">
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="section_1">
      <div class="container d-flex f-between">
        <div class="section_item"
             v-for="(item,index) in concatList"
             :key="index">
          <img :src="item.img"
               alt="">
          <div class="name">{{item.name}}</div>
          <div class="title">{{item.desc}}</div>
          <div class="copy_wrap d-flex">
            <p>{{item.address}}</p><img id="copy"
                 @click="copyText(item.address)"
                 src="../assets/home2/com_copy.png"
                 alt="">
          </div>
          <img class="logo" :src="item.logo" alt="">
        </div>
        <div class="section_item none" style="opacity: 0;"></div>
        <div class="section_item none" style="opacity: 0;"></div>
      </div>
    </div>
    <div class="section_2">
      <div class="tab_wrap d-flex container">
        <div :class="['tab_item', tabIndex == index ? 'active' : '']"
             v-for="(item,index) in tabList"
             :key="index"
             @click="tabClick(item,index)">{{item.title}}</div>
      </div>
      <div class="info_wrap container">
        <div class="info_item"
             v-for="(project,pIndex) in filterWebList"
             :key="pIndex">

         <div class="info-container">
          <div class="info_banner" :class="project.bg">
            <div class="label">{{project.tabTitle}}</div>
          </div>
          <div class="info_title">{{project.name}}</div>
          <div class="info_desc">{{project.desc}}</div>
          <div class="web_info_wrap d-flex">
            <div class="web_info"
                 v-for="(web,wIndex) in project.webList"
                 :key="wIndex">
              <div class="web_title title">{{web.name}}</div>
              <div class="count_title title">帐号:</div>
              <div class="detail d-flex">
                <p>{{web.count}}</p>
                <img src="../assets/home2/com_copy.png"
                     class="copy"
                     @click="copyText(web.count)"
                     alt="">
              </div>
              <div class="count_title title">密码：</div>
              <div class="detail d-flex">
                <p>{{web.pwd}}</p>
                <img src="../assets/home2/com_copy.png"
                     class="copy"
                     @click="copyText(web.pwd)"
                     alt="">
              </div>
              <div class="count_title title">链接:</div>
              <div class="detail d-flex">
                <p>{{web.url}}</p>
                <img src="../assets/home2/com_copy.png"
                     class="copy"
                     @click="copyText(web.url)"
                     alt="">
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>
    </div>
    <!-- <div class="goTop"><img src="../assets/home/class.png" alt=""></div> -->
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      msg: "Welcome to Your Vue.js App",
      tabIndex: 0,
      tabList: [
        {
          title: "全部",
        },
        {
          title: "区块链交易所",
        },
        {
          title: "DAPP套U",
        },
        // {
        //   title: "区块链游戏"
        // },
      ],
      filterWebList: [],
      projectInfo: [
        {
          tabTitle: "区块链交易所",
          bg: "b5",
          name: "项目简介",
          desc: "交易所2型：目前比较完善的交易所盘口，功能模块包含币币交易、法币交易、期货交易(买涨跌)、信托理财、助力贷款、信用分等，支持多币种充值。支持15种语言（英语、中文繁体、中文简体、法语、日语、韩语、马来语、俄语、德语、意大利语、西班牙语、泰语、葡萄牙语、越南语、土耳其语）。移动端和电脑端均可进行交易，官网有6套模版可选择。所有代码均为团队自研，稳定可定制开发。",
          webList: [
            {
              name: "官网",
              count: "+1 111111",
              pwd: "111111",
              url: "https://www.coinvpro.top",
            },
            {
              name: "H5",
              count: "+1 111111",
              pwd: "111111",
              url: "https://www.coinvpro.top/h5",
            },
            {
              name: "管理后台",
              count: "联系客服获取",
              pwd: "联系客服获取",
              url: "联系客服获取",
            },
            {
              name: "代理后台",
              count: "联系客服获取",
              pwd: "联系客服获取",
              url: "联系客服获取",
            },
            
          ],
        },
        {
          tabTitle: "区块链交易所",
          bg: "b2",
          name: "项目简介",
          desc: "交易所3型：该项目为较新的交易模式（DAPP），DAPP是WEB3.0时代软件的趋势，需要用户先下载钱包（如Coinbase Wallet\MetaMask\Trust\Defi wallet等），直接让用户去各大钱包官网下载钱包手机APP，使用钱包内的浏览器打开网页即可完成自动注册和绑定上级，不再需要客户的手机号或者邮箱注册。该项目包含币种兑换、期货交易、AI交易、DAOu(后台可关闭)、理财等，K线可自由定制，支持虚拟币、外汇数据交易，模式新颖，目前是业绩最好的盘口。",
          webList: [
            {
              name: "H5",
              count: "钱包连接",
              pwd: "-",
              url: "https://www.dappdemo.cc",
            },
            {
              name: "管理后台",
              count: "联系客服获取",
              pwd: "联系客服获取",
              url: "联系客服获取",
            },
            {
              name: "代理后台",
              count: "联系客服获取",
              pwd: "联系客服获取",
              url: "联系客服获取",
            }
          ],
        },
        // {
        //   tabTitle: "区块链交易所",
        //   bg: "b4",
        //   name: "项目简介",
        //   desc: "交易所1型：包含期货交易、合买交易、杠杆交易、余额宝功能，后台输赢可群控、点控。支持手机端、电脑端、平板电脑交易，12种语言支持（英文、简体中文、繁体中文、泰文、日文、马来语、韩语、俄语、法语、西班牙语、德语、意大利语），8套官网模版，代码均为自研发，盘口稳定运行多年。",
        //   webList: [
        //     {
        //       name: "官网",
        //       count: "+1 111111",
        //       pwd: "111111",
        //       url: "https://www.kpdpro.top",
        //     },
        //     {
        //       name: "H5",
        //       count: "+1 111111",
        //       pwd: "111111",
        //       url: "https://h5.kpdpro.top",
        //     },
        //     {
        //       name: "管理后台",
        //       count: "联系客服获取",
        //       pwd: "联系客服获取",
        //       url: "联系客服获取",
        //     },
        //     {
        //       name: "代理后台",
        //       count: "联系客服获取",
        //       pwd: "联系客服获取",
        //       url: "联系客服获取",
        //     },
        //   ],
        // },
        // {
        //   tabTitle: "DAPP套U",
        //   bg: "b3",
        //   name: "项目简介",
        //   desc: "ERC20/TRC20多链支持。白天/黑夜模式两套界面设计（后台配置）。用户余额变动TG消息自动播报，自动化的防跑设计，最大化保护商家利益。代理关系清晰。全天24小时客服服务，为您排忧解难！",
        //   webList: [
        //     {
        //       name: "官网",
        //       count: "--",
        //       pwd: "--",
        //       url: "https://www.esm-usd.top",
        //     },
        //     {
        //       name: "H5",
        //       count: "钱包连接",
        //       pwd: "--",
        //       url: "https://defi.esm-usd.top",
        //     },
        //     {
        //       name: "管理后台",
        //       count: "联系客服获取",
        //       pwd: "联系客服获取",
        //       url: "联系客服获取",
        //     },
        //     {
        //       name: "代理后台",
        //       count: "联系客服获取",
        //       pwd: "联系客服获取",
        //       url: "联系客服获取",
        //     },
        //   ],
        // },
        {
          tabTitle: "DAPP套U",
          bg: "b9",
          name: "项目简介",
          desc: "ERC20/TRC20多链支持。用户余额变动TG消息自动播报，自动化的防跑设计，最大化保护商家利益。代理关系清晰。全天24小时客服服务，为您排忧解难！",
          webList: [
            {
              name: "官网",
              count: "--",
              pwd: "--",
              url: "--",
            },
            {
              name: "H5",
              count: "钱包连接",
              pwd: "--",
              // url: "https://defi.defi-pub.top",
              url: "联系客服获取",
            },
            {
              name: "管理后台",
              count: "联系客服获取",
              pwd: "联系客服获取",
              url: "联系客服获取",
            },
            {
              name: "代理后台",
              count: "联系客服获取",
              pwd: "联系客服获取",
              url: "联系客服获取",
            },
          ],
        },
        // {
        //   tabTitle: "区块链游戏",
        //   bg: "b6",
        //   name: "项目简介",
        //   desc: "区块链农场小游戏，丰富的水果品类，购买种子、种植、售卖果实获得不同收益，授权秒U！后台代理关系清晰适用，代理/组长/组员/员工，满足您的业务需要。全天24小时客服服务，为您排忧解难！",
        //   webList: [
        //     {
        //       name: "H5",
        //       count: "钱包连接",
        //       pwd: "测试邀请码：4kmeYyer",
        //       url: "https://h5.ggccamev2.cc",
        //     },
        //     {
        //       name: "管理后台",
        //       count: "联系客服获取",
        //       pwd: "联系客服获取",
        //       url: "联系客服获取",
        //     },
        //     {
        //       name: "代理后台",
        //       count: "联系客服获取",
        //       pwd: "联系客服获取",
        //       url: "联系客服获取",
        //     }
        //   ]
        // },
        // {
        //   tabTitle: "区块链游戏",
        //   bg: "b8",
        //   name: "项目简介",
        //   desc: "该项目需要引导用户下载钱包（如Coinbase Wallet\MetaMask\Trust\Defi wallet等），在钱包浏览器内打开我们的页面，客户输入邀请码可以领到新手礼包，新手礼包后台可配置，用户种植水果以后，需要施肥、浇水、除虫等，最后获得的果实可以出售为USDT，不同的水果出售价格不一样。每天系统会根据用户链上的余额进行赠送金币，金币可以购买更高级的种子，获得更高的收益。后台有自动防止余额转走的功能。",
        //   webList: [
        //     {
        //       name: "H5",
        //       count: "钱包连接",
        //       pwd: "测试邀请码：66520041",
        //       url: "https://h5v3.ggccamev2.cc",
        //     },
        //     {
        //       name: "管理后台",
        //       count: "联系客服获取",
        //       pwd: "联系客服获取",
        //       url: "联系客服获取",
        //     },
        //     {
        //       name: "代理后台",
        //       count: "联系客服获取",
        //       pwd: "联系客服获取",
        //       url: "联系客服获取",
        //     }
        //   ],
        // },
        // {
        //   tabTitle: "区块链游戏",
        //   bg: "b7",
        //   name: "项目简介",
        //   desc: "该项目需要引导用户下载钱包（如Coinbase Wallet\MetaMask\Trust\Defi wallet等），在钱包浏览器内打开我们的页面，客户输入邀请码可以领到新手礼包，新手礼包后台可配置，用户种植水果以后，需要施肥、浇水、除虫等，最后获得的果实可以出售为USDT，不同的水果出售价格不一样。每天系统会根据用户链上的余额进行赠送金币，金币可以购买更高级的种子，获得更高的收益。后台有自动防止余额转走的功能。",
        //   webList: [
        //     {
        //       name: "H5",
        //       count: "钱包连接",
        //       pwd: "测试邀请码：32168521",
        //       url: "https://h5.ggccamev2.cc",
        //     },
        //     {
        //       name: "管理后台",
        //       count: "联系客服获取",
        //       pwd: "联系客服获取",
        //       url: "联系客服获取",
        //     },
        //     {
        //       name: "代理后台",
        //       count: "联系客服获取",
        //       pwd: "联系客服获取",
        //       url: "联系客服获取",
        //     }
        //   ],
        // }
      ],
      concatList: [
      {
          img: require("../assets/home2/telegram.png"),
          name: "Telegram",
          desc: "24小时在线小飞机客服。",
          address: "@Xuntaijituan002",
          logo : require("../assets/home2/telegram_bg.png")
        },
        // {
        //   img: require("../assets/home2/skype.png"),
        //   name: "Skype",
        //   desc: "24小时在线Skype客服。",
        //   address: "live:.cid.e3de6e5c13495d4",
        //   logo : require("../assets/home2/skype_bg.png")
        // },{
        //   img: require("../assets/home2/whats.png"),
        //   name: "WhatsApp",
        //   desc: "24小时在线WhatsApp客服。",
        //   address: "+66 834518224",
        //   logo : require("../assets/home2/whatsapp_bg.png")
        // }, {
        //   img: require("../assets/home2/eMail.png"),
        //   name: "Email",
        //   desc: "请通过Email与我们联系，以便快速响应。",
        //   address: "XunTaiJituan@gmail.com",
        //   logo : require("../assets/home2/email_bg.png")
        // },
        {
          img: require("../assets/home2/telegram.png"),
          name: "Telegram",
          desc: "请通过Telegram与我们联系，以便快速响应。",
          address: "@Xuntaijituan003",
          logo : require("../assets/home2/telegram_bg.png")
        },
        // {
        //   img: require("../assets/home2/skype.png"),
        //   name: "Skype",
        //   desc: "请通过Skype与我们联系，以便快速响应。",
        //   address: "live:.cid.85e3ebc33f892c7c",
        //   logo : require("../assets/home2/skype_bg.png")
        // },
       
        
      ],
    };
  },
  mounted() {
    this.filterWebList = JSON.parse(JSON.stringify(this.projectInfo));
  },
  methods: {
    tabClick(item, index) {
      this.tabIndex = index;
      this.filterWebList = this.filterList(item.title);
    },
    filterList(title) {
      if (title == "全部") {
        return this.projectInfo;
      }
      let list = this.projectInfo.filter((item) => {
        return item.tabTitle == title;
      });
      return list;
    },
    // 复制操作
    copyText(context) {
      // 创建输入框元素
      let oInput = document.createElement("input");
      // 将想要复制的值
      oInput.value = context;
      // 页面底部追加输入框
      document.body.appendChild(oInput);
      // 选中输入框
      oInput.select();
      // 执行浏览器复制命令
      document.execCommand("Copy");
      // 弹出复制成功信息
      this.$message.success("复制成功");
      // 复制后移除输入框
      oInput.remove();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
::v-deep .el-carousel__container {
  height: 860px;
  .el-carousel__arrow {
    width: 60px;
    height: 60px;
    font-size: 25px;
  }
}
::v-deep .el-carousel__indicators--horizontal {
  background: rgba(153, 137, 218, 0.1);
  border-radius: 12px;

  .el-carousel__indicator--horizontal {
    padding: 7px 15px;
    .el-carousel__button {
        width: 15px;
        height: 15px;
        border-radius: 50%;
        background: #fff;
    }
    &.is-active .el-carousel__button{
      background:#9989DA ;
    }
  }
}
.home_wrap{
  background: #F5F4F4;
  font-family: 'PingFang SC';
  padding-bottom: 110px;
  background-image: url('../assets/home2/body-bg.jpg');
  background-size: 100% auto;
  background-position: 0 0;
}
p {
  margin: 0;
}
div {
  box-sizing: border-box;
}
.d-flex {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.f-between {
  justify-content: space-between;
}

.top_banner {
  width: 100%;
  height: 860px;
  position: relative;
}
img {
  width: 100%;
  height: 100%;
}
.section_1 {
  padding: 120px 0;
  // background: #f6f8fb;
  position: relative;
}
.container {
  width: 86vw;
  margin: 0 auto;
}
.goTop {
  position: fixed;
  right: 80px;
  bottom: 107px;
  img {
    width: 128px;
    height: 128px;
  }
}
.blur{
  position: absolute;
  width: 710px;
  height: 710px;
  right: 88px;
  top: 0;
  background: rgba(255, 24, 35, 0.3);
  filter: blur(115px);
}
.blur2{
  width: 629px;
  height: 629px;
  right: 488px;
  top: 347px;
  background: #FAD8A6;
}
.blur3{
  right: 88px;
  top: 473px;
  background: #BAD4DF;
}
.section_1 .section_item {
  // width: 19.8vw;
  flex: 1;
  height: 274px;
  background: rgba(255, 255, 255, 0.2);
  border: 1px solid #FFFFFF;
  border-radius: 24px;
  padding: 1vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 16px;
  margin-right: 16px;
  position: relative;
  &:nth-child(4n){
    margin-right: 0;
  }
  .title {
    font-size: 14px;
    color: #6F7481;
    letter-spacing: 0;
    font-weight: 400;
  }
  .logo{
    position: absolute;
    right: 0;
    top: 0;
    width: 150px;
    height: 150px;
  }
  .copy_wrap {
    font-size: 16px;
    color: #9989DA;
    letter-spacing: 0;
    font-weight: 400;
    width: 320px;
    height: 40px;
    background: rgba(153, 137, 218, 0.1);
    border-radius: 24px;
    justify-content: space-between;
    padding: 0 10%;
    img {
      width: 16px;
      height: 16px;
      cursor: pointer;
    }
  }
}
.section_1 .section_item img {
  width: 60px;
  height: 60px;
}
.section_1 .section_item .name {
  font-family: 'PingFang SC';
  font-size: 24px;
  color: #041932;
  letter-spacing: 0;
  font-weight: 500;
}
.section_2 {
  // padding: 150px 0;
  .tab_wrap {
    margin-bottom: 60px;
    justify-content: center;
    .tab_item {
      width: 280px;
      height: 68px;
      text-align: center;
      line-height: 66px;
      background: rgba(153, 137, 218, 0.1);
      border-radius: 32px;
      font-size: 24px;
      color: #6F7481;
      letter-spacing: 0;
      font-weight: 400;
      margin-right: 40px;
      cursor: pointer;
      &.active {
        background: #9989DA;
        box-shadow: inset -4px 4px 4px rgba(255, 255, 255, 0.3), inset 4px -4px 4px rgba(255, 255, 255, 0.3);
        color: #fff;
      }
      &:last-child{
        margin-right: 0;
      }
    }
  }
  .info_wrap {
    .info_item {
      margin-bottom: 40px;
      margin-top: 230px;
      .info-container{
        background: rgba(255, 255, 255, 0.4);
        border: 1px solid #FFFFFF;
        border-radius: 24px;
        padding: 40px 80px;
        position: relative;
        padding-top: 450px;
      }
      .info_banner {
        background: #eaeaea;
        border-radius: 10px;
        width: 91%;
        height: 589px;
        position: absolute;
        background-size: cover !important;
        top: -160px;
        left: 50%;
        transform: translateX(-50%);
        &.b2{
          background: url(../assets/banner/b2.jpg) no-repeat;
        }
        &.b3{
          background: url(../assets/banner/b3.png) no-repeat;
        }
        &.b4{
          background: url(../assets/banner/b4.png) no-repeat;
        }
        &.b5{
          background: url(../assets/banner/b5.jpg) no-repeat;
        }
        &.b6{
          background: url(../assets/banner/b6.jpg) no-repeat;
        }
        &.b7{
          background: url(../assets/banner/b7.jpg) no-repeat;
        }
        &.b8{
          background: url(../assets/banner/b8.jpg) no-repeat;
        }
        &.b9{
          background: url(../assets/banner/b9.jpg) no-repeat;
        }
        .label {
          background: linear-gradient(95.14deg, #9989DA 0%, #8168E3 100%);
          border-radius: 12px 0px;
          min-width: 160px;
          height: 48px;
          font-size: 16px;
          color: #ffffff;
          letter-spacing: 0;
          text-align: center;
          font-weight: 400;
          position: absolute;
          left: 0;
          top: 0;
          text-align: center;
          line-height: 48px;
          padding: 0 32px;
        }
      }
      .info_title {
        font-size: 20px;
        color: #041932;
        letter-spacing: 0;
        font-weight: 400;
        margin-top: 24px;
      }
      .info_desc {
        font-size: 14px;
        color: #6F7481;
        letter-spacing: 0;
        font-weight: 400;
        margin-top: 12px;
      }
      .web_info_wrap {
        width: 100%;
        justify-content: space-between;
        margin-top: 24px;
        .web_info {
          flex: 1;
          margin-right: 16px;
          background: rgba(153, 137, 218, 0.1);
          border-radius: 8px;
          padding: 24px;
          &:last-child{
            margin-right: 0;
          }
          img {
            width: 16px;
            height: 16px;
            margin-left: 20px;
            cursor: pointer;
          }
          .title {
            font-size: 14px;
            color: #041932;
            letter-spacing: 0;
            // line-height: 25px;
            font-weight: 400;
          }
          .detail {
            font-size: 14px;
            color: #6F7481;
            letter-spacing: 0;
            // line-height: 25px;
            font-weight: 400;
            margin-bottom: 15px;
          }
          .web_title {
            font-size: 18px;
            color: #9989DA;
            letter-spacing: 0;
            font-weight: 400;
            margin-bottom: 15px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1200px) {
  ::v-deep .el-carousel__indicators--horizontal {
    font-size: 20px;
    .el-carousel__indicator--horizontal {
      padding: 7px 30px;
      .el-carousel__button {
          width: 30px;
          height: 30px;
      }
    }
  }
  ::v-deep .el-carousel__container {
    .el-carousel__arrow {
      width: 90px;
      height: 90px;
      font-size: 35px;
    }
  }
  .section_1 {
    .container {
      flex-wrap: wrap;
      .section_item {
        width: 100%;
        flex: inherit;
        height: 700px;
        padding: 50px 40px;
        margin-bottom: 50px;
        img{
          width: 120px;
          height: 120px;
        }
        .logo{
          width: 300px;
          height: 300px;
        }
        &.none{
          display: none;
        }
        .copy_wrap {
          width: 80%;
          height: 100px;
          img {
            width: 60px;
            height: 60px;
          }
        }
      }
    }
  }
  .section_2 .tab_wrap .tab_item {
    width: auto;
    height: auto;
    padding: 20px 50px;
  }
  .section_2 .info_wrap .info_item {
    .info_banner .label {
      width: auto;
      height: auto;
      padding: 20px 30px;
    }
    .web_info_wrap {
      flex-wrap: wrap;
      align-items: flex-start;
      .web_info {
        width: 48%;
        flex: inherit;
        margin-bottom: 40px;
        word-break: break-all;
        img {
          width: 60px;
          height: 60px;
        }
        .web_title {
          margin-bottom: 30px;
        }
        .detail,
        .title {
          line-height: auto;
        }
      }
    }
  }
}
</style>
